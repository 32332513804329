import React from 'react';

const Nav = ({ onMenuClick, isLightMode, isPlaying, isMuted }) => {
    return (
        <div className='nav-menu-container'>
            <div className='nav-menu'>
                <div className='nav-menu-item nav-menu-terminal nav-menu-selected'>
                    Terminal
                </div>
                <div className='nav-menu-item' onClick={() => onMenuClick('node')}>
                    Nodes
                </div>
                <div className='nav-menu-item' onClick={() => onMenuClick('social')}>
                    Socials
                </div>
                <div className='nav-menu-item nav-menu-mascot' onClick={() => onMenuClick('nekohime')}>
                    Nekohime
                </div>
                {/*<div className='nav-menu-item nav-menu-faucet' onClick={() => onMenuClick('faucet')}>
                    Faucet
                </div>*/}
                <div className='nav-menu-item nav-menu-dark' onClick={() => onMenuClick(isLightMode ? 'dark' : 'light')}>
                    {isLightMode ? 'Dark' : 'Light'}
                </div>
                <div className='nav-menu-item' onClick={() => onMenuClick(isPlaying ? 'stop' : 'party')}>
                    {isPlaying ? 'Stop' : 'Party'}
                </div>
                {isPlaying && (
                    <div className='nav-menu-item' onClick={() => onMenuClick('next')}>
                        Next
                    </div>
                )}
                {isPlaying && (
                    <div className='nav-menu-item' onClick={() => onMenuClick(isMuted ? 'unmute' : 'mute')}>
                        {isMuted ? 'Unmute' : 'Mute'}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Nav;