import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-copyright'>
                v0.1 (C) COPYRIGHT 2024, NODE CATTEL GROUP
            </div>
        </div>
    );
};

export default Footer;