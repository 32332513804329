import React, { useState, useRef } from 'react';
import Header from './components/Header';
import NavMenu from './components/NavMenu';
import Terminal from './components/Terminal';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  const [lightMode, setLightMode] = useState(false);
  const [navCommand, setNavCommand] = useState('');
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);

  const songs = [
    '/musics/meow-meow.mp3',
    '/musics/node-cattel-heroes.mp3',
    '/musics/the-node-cattel-2.mp3',
    '/musics/the-node-cattel.mp3'
];

  const playMusic = () => {
    if (audioRef.current) {
      audioRef.current.src = songs[currentSongIndex];
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const stopMusic = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    };
  };

  const nextMusic = () => {
    const nextIndex = (currentSongIndex + 1) % songs.length;
    setCurrentSongIndex(nextIndex);
    if (audioRef.current) {
      audioRef.current.src = songs[nextIndex];
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const toggleVolume = () => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
      setIsMuted(audioRef.current.muted);
    }
  };

  const handleMenuClick = (command) => {
      setNavCommand(command);
  };

  const resetNavCommand = () => {
    setNavCommand('');
  };


  const toggleTheme = () => {
    setLightMode(!lightMode);
    document.body.classList.toggle('light-mode');
  };

  return (
    <div className={`app ${lightMode ? 'light-mode' : ''} main-wrapper`}>
      <div className='main-content'>
        <Header />
        <NavMenu 
          onMenuClick={handleMenuClick}
          isLightMode={lightMode}
          isPlaying={isPlaying}
          isMuted={isMuted}  
        />
        <Terminal 
          navCommand={navCommand}
          resetNavCommand={resetNavCommand}
          toggleTheme={toggleTheme} 
          lightMode={lightMode} 
          playMusic={playMusic}
          stopMusic={stopMusic}
          nextMusic={nextMusic}
          toggleVolume={toggleVolume}
          isMuted={isMuted}
        />
        <Footer />
        <audio ref={audioRef} />
      </div>
    </div>
  );
};

export default App;
