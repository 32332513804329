import React from 'react';

const Header = () => {
    return (
        <div className='nav'>
            <div className='nav-header'>
                <div className='nav-header-item nav-header-title'>
                    NODECATTEL HUB
                </div>
            </div>
        </div>
    );
};

export default Header;